// preview.js file

import * as React from 'react'
import {withPrismicPreviewResolver} from 'gatsby-plugin-prismic-previews'
import styled from "styled-components";

const Holder = styled.div`
  padding: 96px 0;
  background-color: ${props => props.theme.colors.yellow};
  height: 80vh;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 132px 0;
  }
`;

const PreviewPage = () => {
  return (
    <Holder>
      <div className="container">
        <h1>Loading preview…</h1>
      </div>
    </Holder>
  )
}

export default withPrismicPreviewResolver(PreviewPage)